import getErrorList from './getErrorList';
import itcDataUpdation from '../../gstrTwoA/itcDataUpdation';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'errorSuccess',
  props: ['rowDtls', 'dataType'],
  watch: {
    currentPage: function() {
      this.getprErrorSuccessDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getprErrorSuccessDtls();
    }
  },
  components: {
    getErrorList,
    itcDataUpdation
  },
  data() {
    return {
      loader: false,
      errorSuccessData: [],
      errorSuccessFields: [
        // { key: 'fileName' },
        {
          key: 'accountingDocumentDate',
          label: 'Acc. Doc. Date',
          class: 'col-fix'
        },
        {
          key: 'accountingDocumentNumber',
          label: 'Acc. Doc. No.',
          class: 'col-fix'
        },
        { key: 'accountingPeriod', label: 'Acc. Period', class: 'col-fix' },
        { key: 'accountingYear', class: 'col-fix' },
        { key: 'gstin', label: 'GSTIN', class: 'col-fix' },
        { key: 'gSTINOfSupplier', label: 'supplier GSTIN', class: 'col-fix' },
        { key: 'supplyType', class: 'col-fix' },
        { key: 'nameOfSupplier', class: 'col-fix' },
        { key: 'supplyAttractReverseCharge', class: 'col-fix' },
        { key: 'pos', label: 'POS', class: 'col-fix' },
        { key: 'lineItem', class: 'col-fix' },
        { key: 'hsnOrSac', label: 'HSN Of SAC', class: 'col-fix' },
        { key: 'taxableValue', class: 'col-fix' },
        { key: 'totalGSTRate', label: 'Total GST Rate', class: 'col-fix' },
        { key: 'iGSTAmount', label: 'IGST Amount', class: 'col-fix' },
        { key: 'iGSTRate', label: 'IGST Rate', class: 'col-fix' },
        { key: 'cGSTAmount', label: 'CGST Amount', class: 'col-fix' },
        { key: 'cGSTRate', label: 'CGST Rate', class: 'col-fix' },
        { key: 'sGSTAmount', label: 'SGST Amount', class: 'col-fix' },
        { key: 'sGSTRate', label: 'SGST Rate', class: 'col-fix' },
        { key: 'category', class: 'col-fix' },
        { key: 'cessAmount', class: 'col-fix' },
        { key: 'cessRate', class: 'col-fix' },
        { key: 'itcTotalAmount', class: 'col-fix' },
        { key: 'itcIGSTAmount', class: 'col-fix' },
        { key: 'itcCGSTAmount', class: 'col-fix' },
        { key: 'itcSGSTAmount', class: 'col-fix' },
        { key: 'itcCessAmount', class: 'col-fix' },
        { key: 'totalGst', label: 'Total GST', class: 'col-fix' },
        { key: 'forMIS5', label: 'For MIS5', class: 'col-fix' },
        { key: 'currency', class: 'col-fix' },
        { key: 'dataGstType', label: 'GST Type', class: 'col-fix' },
        {
          key: 'destinationShippingState',
          label: 'Dest. Ship. State',
          class: 'col-fix'
        },
        { key: 'documentType', class: 'col-fix' },
        { key: 'editInProgress', class: 'col-fix' },
        { key: 'eligibilityOfItc', class: 'col-fix' }
      ],
      fileId: null,
      loadId: null,
      reqId: null,
      type: null,
      heading: null,
      showErrorListModal: false,
      showModifyDataModal: false,
      errorListArray: [],
      totalPayload: null,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      perPage: 1
    };
  },
  mounted() {
    if (this.rowDtls) {
      this.fileId = this.rowDtls.file_id;
      this.loadId = this.rowDtls.ext_file_id;
      this.reqId = this.rowDtls.request_id;
      this.type = this.dataType;
      if (this.dataType === 'Error') {
        this.errorSuccessFields.push({
          key: 'getErrorList',
          label: 'Error List'
        });
      }
      this.heading = this.rowDtls.file_name;
    }
    if (this.rowDtls && this.dataType) {
      this.getprErrorSuccessDtls();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.totalPayload };
          this.downloadExcel.downloadData(
            downloadpayload,
            'complainceDashboard/getprIntErrSuccessData',
            'Success-Error Details',
            () => (this.loader = false),
            this.errorSuccessData
          );
        }
      }
    });
  },
  methods: {
    getprErrorSuccessDtls() {
      this.errorSuccessData = [];
      const payload = {
        file_id: this.fileId,
        // request_id: this.reqId,
        load_id: this.loadId,
        data_type: this.type,
        page: this.currentPage - 1,
        limit: this.perPage
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch('complainceDashboard/getprIntErrSuccessData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page.data.content;
            this.errorSuccessData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    modifyDataFunc() {
      this.showModifyDataModal = true;
      // this.eventBus.$emit('ModifyPrErrorData', this.errorSuccessData);
    },
    errorListModal(flag) {
      this.showErrorListModal = flag;
    },
    showOpenErrorListModal(index, item) {
      this.showErrorListModal = true;
      this.errorListArray = item.errorsList;
    },
    showHideModifyDataModal(flag) {
      this.showErrorSuccessModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
