import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../../../utility/url.utility';
export default {
  name: 'synkPr',
  props: ['syncType'],
  components: { DatePicker },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      legalEntity: { text: null, value: null },
      syncDateFrom: null,
      syncDateTo: null,
      showValueSetModal: false,
      editMode: false,
      requestNumId: null,
      requestStatus: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      responseMsg: '',
      showAlert: false,
      isSuccess: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          if (this.syncType === 'generatePr') {
            // this.saveSubmitRequest();
            this.addEditGeneratePr()
          } else if (this.syncType === 'syncRecoStatus') {
            this.syncRecoStatusData();
          }
        }
      }
    });
  },
  methods: {
    // saveSubmitRequest() {
    //   const payload = {
    //     admin_submit_req_details: [
    //       {
    //         request_num: 0,
    //         request_id: '243', //this.requestId,
    //         template_id: '73', //this.templateId,
    //         schedule_type: 'asap', //pass hardcode
    //         request_start_date: null,
    //         request_end_date: null,
    //         resubmit_interval: null,
    //         resubmit_interval_unit: null,
    //         day_of_month: null,
    //         day_of_week: null,
    //         output_format_id: 'csv', //pass hardcode
    //         no_of_args: 25,
    //         request_parameter:
    //           this.syncDateFrom +
    //           ',' +
    //           this.syncDateTo +
    //           ',' +
    //           this.legalEntity.value,
    //         sms_flag: false,
    //         email_flag: false,
    //         whatsapp_flag: false,
    //         child_req_count: null,
    //         email_comm_template_id: null,
    //         sms_comm_template_id: null,
    //         whatsapp_comm_template_id: null,
    //         argument1: this.syncDateFrom,
    //         argument2: this.syncDateTo,
    //         argument3: this.legalEntity.value,
    //         argument4: null,
    //         argument5: null,
    //         argument6: null,
    //         argument7: null,
    //         argument8: null,
    //         argument9: null,
    //         argument10: null,
    //         argument11: null,
    //         argument12: null,
    //         argument13: null,
    //         argument14: null,
    //         argument15: null,
    //         argument16: null,
    //         argument17: null,
    //         argument18: null,
    //         argument19: null,
    //         argument20: null,
    //         argument21: null,
    //         argument22: null,
    //         argument23: null,
    //         argument24: null,
    //         argument25: null
    //       }
    //     ]
    //   };
    //   // this.loader = true;
    //   this.$store
    //     .dispatch('template/saveSubmitRequest', payload)
    //     .then(resp => {
    //       // this.loader = false;
    //       if (resp.status === 201) {
    //         this.requestNumId = resp.data.data[0].id;
    //         this.getSubmitRequest();
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // getSubmitRequest() {
    //   // this.loader = true;
    //   this.$store
    //     .dispatch('template/getSubmitRequest', this.requestNumId)
    //     .then(resp => {
    //       if (resp.status === 200) {
    //         this.requestStatus = resp.data.data[0].status;
    //         this.reqId = resp.data.data[0].id;
    //         if (this.requestStatus === 'COMPLETED') {
    //           // this.loader = false;
    //           this.addEditSyncPrIntegration(this.reqId);
    //         } else if (this.requestStatus === 'ERROR') {
    //           // this.loader = false;
    //           alert('Request in ERROR can not Preview');
    //         } else {
    //           setTimeout(() => {
    //             this.getSubmitRequest();
    //           }, 3000);
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    addEditSyncPrIntegration() {
      const payload = {
        // request_id: requestId,
        le_id: this.legalEntity.value,
        from_date: this.syncDateFrom,
        to_date: this.syncDateTo
      };
      // this.loader = true;
      this.$store
        .dispatch('complainceDashboard/addEditSyncPrIntegration', payload)
        .then(resp => {
          // this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.$emit('searchSyncPrIntegraion', this.legalEntity);
          } else {
            this.isSuccess = false;
            this.responseMsg = resp.data.message;
          }
        })
        .catch(() => {
          // this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditGeneratePr() {
      const payload = {
        // request_id: requestId,
        le_id: this.legalEntity.value,
        from_date: this.syncDateFrom,
        to_date: this.syncDateTo
      };
      // this.loader = true;
      this.$store
        .dispatch('complainceDashboard/addEditGeneratePr', payload)
        .then(resp => {
          // this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.$emit('searchSyncPrIntegraion', this.legalEntity);
          } else {
            this.isSuccess = false;
            this.responseMsg = resp.data.message;
          }
        })
        .catch(() => {
          // this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.syncDateFrom);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
      }
    },
    syncRecoStatusData() {
      const payload = {
        pr_repo_status_req: [
          {
            from_date: this.syncDateFrom,
            to_date: this.syncDateTo,
            le_id: +this.legalEntity.value
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('complainceDashboard/syncPrRecoStatus', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            setTimeout(() => {
              this.showAlert = false;
              this.isSuccess = false;
              this.responseMsg = '';
            }, 3000);
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
