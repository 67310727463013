export default {
  name: 'getErrorList',
  props: ['errorListArray'],
  data() {
    return {
      errorListData: [],
      errorListFields: [
        {
          key: 'errorMessage',
          class: 'col-fix'
        },
        {
          key: 'failureType',
          class: 'col-fix'
        }
      ]
    };
  },
  mounted() {
    if (this.errorListArray) {
      this.errorListData = this.errorListArray;
    }
  }
};
